<template>
  <Button v-if="order.items && order.items.length" class="mt-2 mr-2" small @click="$emit('click')"><v-icon class="mr-2">mdi-cart</v-icon>Checkout</Button>
</template>
  
<script>
  import Store from '@/services/Store.js';
  export default {
    components: {
      Button: () => import('./Button'),
    },  

    data () {
      return {
        order: Store.order,
      }
    },

}
</script>
